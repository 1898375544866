$font-family: "Inter", sans-serif;

$sidebar-width: 267px;

$color-primary: #000000;
$color-black-01: #4b4b4b;
$color-black-02: #5e5e5e;
$color-black-03: #757575;

$color-grey-00: #f6f6f6;
$color-grey-01: #f3f3f3;
$color-grey-02: #e8e8e8;
$color-grey-03: #e2e2e2;
$color-grey-04: #a6a6a6;

$color-orange: #f5ecdd;

$color-blue: #d4e2fc;

$color-gradient-01: #4a2cd7;
$color-gradient-02: #0594e0;

$color-white: #ffffff;

$color-success: #26ce6a;
$color-success-trans: rgba(38, 206, 106, 0.15);
$color-error: #fb190b;
$color-error-trans: rgba(251, 25, 11, 0.15);

$box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
