.alert_container {
  position: fixed;
  right: 12px;
  top: 12%;
  display: flex;
  height: auto;
  width: fit-content;
  width: -moz-fit-content;
  max-width: 550px;
  border-radius: 3px;
  box-shadow: 0px 4px 7px rgba(26, 31, 76, 0.04);
  z-index: 10000;

  @media screen and (max-width: 420px) {
    right: 0px;
    margin: 0 5px;
  }

  &.success {
    color: $color-white;
    border: 1px solid $color-success-trans;
    border-left-width: 4px;
    background: $color-success;

    a {
      color: $color-primary;
    }
  }

  &.error {
    color: $color-white;
    border: 1px solid $color-error-trans;
    border-left-width: 4px;
    background: $color-error;

    a {
      color: $color-primary;
    }
  }

  .alert {
    margin: 0;
    padding: 13px 13px;
    padding-bottom: 14px;

    @media screen and (max-width: 420px) {
      padding: 9px 9px;
    }

    p {
      margin: 0;
      font-size: 0.95em;
      font-weight: 700;
      color: $color-white;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;

      @media screen and (max-width: 420px) {
        font-size: 1em;
      }

      a {
        font-weight: bold;
        text-decoration: underline;
      }
    }

    button {
      margin-top: 4px;
      padding: 4px 8px;
      padding-bottom: 5px;
      font-size: 0.9em;
    }
  }

  .close_div {
    padding: 0px 2px;
    display: flex;
    align-items: center;
    height: inherit;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 0px 3px 3px 0px;
    cursor: pointer;

    svg {
      color: $color-white;
    }
  }
}
